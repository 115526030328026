<template>
  <section>

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              {{ pageType }} {{ resourceName }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col
        md="12"
        class="mb-2 text-right"
      >
        <b-button
          v-if="!readonlyStatus"
          variant="primary"
          class="mr-1"
          :to="{ name: 'view-profile' }"
        >
          <feather-icon
            icon="FileTextIcon"
            size="16"
            class="align-text-top"
          /> View {{ resourceName }}
        </b-button>
        <b-button
          v-if="readonlyStatus"
          variant="primary"
          class="mr-1"
          :to="{ name: 'edit-profile' }"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
            class="align-text-top"
          /> Edit {{ resourceName }}
        </b-button>
      </b-col>
    </b-row>

    <!-- form card -->
    <b-card class="p-1">
      <b-card-title class="pl-1 border-left-primary border-left-3">
        {{ pageType }} {{ resourceName }}
      </b-card-title>

      <!-- loading spinner -->
      <div
        v-if="!userData"
        class="text-center"
      >
        <b-spinner
          style="width: 3rem; height: 3rem;"
          class="m-1"
          label="Loading User Form"
        />
      </div>

      <!-- form -->
      <div v-if="userData">
        <validation-observer
          ref="resourceForm"
          #default="{invalid, dirty}"
        >
          <b-form @submit.prevent="updateUser">
            <b-row>

              <!-- email -->
              <b-col md="12">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                    vid="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="formUserData.email"
                      type="email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Email"
                      :readonly="readonlyStatus"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- first name -->
              <b-col md="12">
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                    vid="first_name"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="formUserData.first_name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="First Name"
                      :readonly="readonlyStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- last name -->
              <b-col md="12">
                <b-form-group
                  label="Last Name"
                  label-for="last_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                    vid="last_name"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="formUserData.last_name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Last Name"
                      :readonly="readonlyStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- mobile -->
              <b-col md="12">
                <b-form-group
                  label="Mobile Phone"
                  label-for="mobile"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Mobile Phone"
                    vid="mobile"
                  >
                    <b-form-input
                      id="mobile"
                      v-model="formUserData.mobile"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Mobile Phone"
                      :readonly="readonlyStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit -->
              <b-col
                v-if="!readonlyStatus"
                cols="6"
                class="mt-1 text-left"
              >
                <b-overlay :show="submitLoading">
                  <b-button
                    v-b-modal.modal-password-change
                    variant="outline-primary"
                  >
                    Change Password
                  </b-button>
                </b-overlay>
              </b-col>
              <b-col
                v-if="!readonlyStatus"
                cols="6"
                class="mt-1 text-right"
              >
                <b-overlay :show="submitLoading">
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="invalid || !dirty"
                  >
                    Save Changes
                  </b-button>
                </b-overlay>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>

    <b-modal
      id="modal-password-change"
      hide-footer
      centered
      title="Change Password"
    >
      <b-card-text class="mb-1">
        <!-- password change form -->
        <validation-observer
          ref="passwordChangeForm"
          #default="{invalid, dirty}"
        >
          <b-form
            @submit.prevent="changePassword"
          >
            <b-row>
              <b-col md="12">
                <!-- password -->
                <b-form-group
                  label-for="password"
                  label="Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required|confirmed:c_password"
                    vid="password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="formPasswordData.password"
                        class="form-control-merge"
                        type="password"
                        :state="errors.length > 0 ? false:null"
                        name="password"
                        placeholder="············"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <!-- confirm password -->
                <b-form-group
                  label-for="c_password"
                  label="Confirm Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required"
                    vid="c_password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="c_password"
                        v-model="formPasswordData.confirmPassword"
                        class="form-control-merge"
                        type="password"
                        :state="errors.length > 0 ? false:null"
                        name="c_password"
                        placeholder="············"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <!-- action button -->
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid || !dirty"
                >
                  Change Password
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
  BCard, BCardText, BCardTitle,
  BForm, BFormGroup, BFormInput, BInputGroup,
  BButton, BOverlay, BModal, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BOverlay,
    BModal,
    BSpinner,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      resourceName: 'Profile',
      userData: JSON.parse(localStorage.getItem('userData')),
      submitLoading: false,

      // form data
      formUserData: {},
      formPasswordData: {},

      // validation
      required,
      email,
    }
  },
  computed: {
    pageType() {
      let pageType = 'View'
      if (this.$route.name === 'edit-profile') {
        pageType = 'Edit'
      }
      return pageType
    },
    readonlyStatus() {
      let readonlyStatus = true
      if (this.pageType === 'Edit') {
        readonlyStatus = false
      }
      return readonlyStatus
    },
  },
  created() {
    // get current user data
    if (this.userData) {
      useJwt.getCurrentUser()
        .then(response => {
          const newUserData = response.data.data
          this.userData = { ...this.userData, ...newUserData }
          console.log('userData', this.userData)

          this.formUserData = newUserData // set form data
        })
        .catch(error => {
          console.log('not logged in', error)
        })
    }
  },
  methods: {
    formRoleChanged() {
      this.roleChanged = true
    },
    updateUser() {
      /* update user data */
      this.$refs.resourceForm.validate().then(success => {
        if (success) {
          this.submitLoading = true
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.resourceForm.reset()

          const formData = this.formUserData
          delete formData.email

          useJwt.updateUser(
            {
              ...formData,
            },
          )
            .then(response => {
              this.submitLoading = false
              const resourceData = response.data
              console.log('updateUser', resourceData) // DELETE LATER

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `User ${resourceData.system_user_name} Updated`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'User updated successfully.',
                },
              })
              this.$router.push({ name: 'view-profile' })
            })
            .catch(error => {
              this.submitLoading = false
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.resourceForm.setErrors(error.response.data.error)
              }
            })
        }
      })
    },
    changePassword() {
      /* update user password */
      this.$refs.passwordChangeForm.validate().then(success => {
        if (success) {
          // reset form validation state
          this.$refs.passwordChangeForm.reset()
          useJwt.updatePassword({
            email: this.userData.email,
            password: this.formPasswordData.password,
            c_password: this.formPasswordData.confirmPassword,
          })
            .then(response => {
              const responseData = response.data.data
              console.log('responseData', responseData)
              this.$bvModal.hide('modal-password-change')

              // notif
              this.$swal({
                icon: 'success',
                title: 'Password Changed',
                text: 'Your password has been changed successfully.',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            })
            .catch(error => {
              console.log(error.response)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
